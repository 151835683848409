import request from '@/utils/request'
/*
    登录
    account	是	string	员工账号
    password	是	string	密码
    captcha	否	string	验证码
*/
export function login(data) {
    return request({
        url: '/admin/login/login',
        method: 'post',
        data: data
    })
}
/*
    分类
*/
export function fl() {
    return request({
        url: '/v1/categories',
        method: 'get',
    })
}
/*
   文章列表
    page	是	string	1
    limit	是	string	10
*/
export function index(data) {
    return request({
        url: 'https://clb.sportqx.com/web/news/index',
        method: 'post',
        data: data
    })
}
/*
   文章详情
   id	是	string	ID
*/
export function detail(data) {
    return request({
        url: 'https://clb.sportqx.com/web/news/detail',
        method: 'post',
        data:data
    })
}