const pageInfo = {
    state: {
        indexPage: {topImage:[
            require('@/assets/banner1-4.jpg'),
            require('@/assets/banner2-2.jpg'),
            require('@/assets/banner3.jpg'),
        ],
            topAppPage:require('@/assets/top.jpg'),
            appPage:[
                require('@/assets/index-1.jpg'),
                require('@/assets/index-2.jpg'),
                require('@/assets/index-3.jpg'),
                require('@/assets/index-4.jpg'),
                require('@/assets/index-5.jpg'),
                require('@/assets/index-6.jpg'),
            ],
            goodsPage:[
                {title:'倾动智能体脂秤',subtitle:'高性能芯片 极致感知身体19项健康数据',image:require('@/assets/tzc.png')},
                {title:'倾行智能手环',subtitle:'随身记录运动轨迹 日常活动数据',image:require('@/assets/sh.png')},
                {title:'倾动智能围度尺',subtitle:'贴合身材 曲线变化一目了然',image:require('@/assets/wd.png')},
            ],
            aboutPage:[
                require('@/assets/1.jpg'),
                require('@/assets/2.jpg'),
                require('@/assets/3.jpg'),
                require('@/assets/4.jpg'),
                require('@/assets/5.jpg'),
                require('@/assets/6.jpg'),
                require('@/assets/7.jpg'),
                require('@/assets/8.jpg'),
                // require('@/assets/9.jpg'),
                // require('@/assets/10.jpg'),

            ]
        },
        allPage:{
            code:require('@/assets/code.jpg'),
            Android:require('@/assets/Android.png'),
            iOS:require('@/assets/ios.png'),
            downloadAndroidUrl:'https://appstore.huawei.com/app/C101079045',
            downloadIosUrl:'https://apps.apple.com/cn/app/id1449690023'
        },
        AppPage:[
            {
                type:1,
                image:require('@/assets/APP.png'),
                title:require('@/assets/app_page_title.png'),
                width:'550px',
                height:'450px',
            },
            {
                type:2,
                image: require('@/assets/app_page_image 4.png'),
                title:'丰富健身课程',
                describe:'专业教练编排训练计划，<br/>超过1000个动作课程，<br/>助你科学运动不走弯路。',
                width:'623px',
                height:'531px',
            },
            {
                type:2,
                image: require('@/assets/app_page_image 1.png'),
                title:'专业知识科普',
                describe:'优质科普内容，\n' +
                    '<br/>' +
                    '科学轻运动健身知识，' +
                    '<br/>' +
                    '减脂路上不迷茫。',
                width:'444px',
                height:'531px',
            },
            {
                type:2,
                image: require('@/assets/app_page_image 2.png'),
                title:'户外运动',
                describe:'徒步、跑步、骑行，多种运动模式任你选择，' +
                    '<br/>' +
                    '轨迹、心率、计步、配速数据一目了然，' +
                    '<br/>' +
                    '户外运动也有科学指导。',
                width:'453px',
                height:'531px',
            },
            {
                type:2,
                image: require('@/assets/app_page_image 3.png'),
                title:'活跃社区',
                describe:'用图片和视频分享你的坚持和感悟，<br/>' +
                    '在社区找到志同道合的好友。',
                width:'500px',
                height:'558px',
            },
        ],
        equipmentPage:[
            {
                name:'倾动智能围度尺',
                title:'日本进口的高性能机芯，成就精准品质',
                subTitle:'量化呈现身体围度变化',
                describe:'柔软、耐磨、顺滑、不割手<br>' +
                    '卡槽采用卡口式布局，轻松固定，自动卡位，单手即可操作<br>' +
                    '贴合身材 曲线变化一目了然',
                image:require('@/assets/wd.png')
            },
            {
                name:'倾动智能跳绳',
                title:'三种跳绳模式，自由随心，乐享跳动',
                subTitle:'实时记录跳绳情况和热量消耗',
                describe:'双霍尔传感器，双轴承，稳固顺滑、高速旋转，不绕绳、不卡顿，360°完整转动，精准记录数据；磨砂手柄，亲肤耐用，握感舒适。',
                image:require('@/assets/ts.png')
            },
            {
                name:'倾动智能体脂秤',
                title:'高性能芯片，极致感知身体19项健康数据',
                subTitle:'量化呈现身材数据，健康报告即刻掌握',
                describe:'采用四点接触式生物电极片测量方案，搭配高性能的芯片、高精度传感器，能够感知身体各项健康数据。称面精细打磨，光洁如镜，灵敏感知轻微重量。',
                image:require('@/assets/tzc.png')
            },
            {
                name:'倾行运动手环',
                title:'随身记录运动轨迹 日常活动数据',
                subTitle:'时尚与实用兼备的智能运动手环',
                describe:'健康数据：APP记录您的日常运动轨迹<br>' +
                    '睡眠监测：帮助您了解自己的睡眠状态<br>' +
                    '心率监测：运动实时心率监测<br>' +
                    '防水设计：IP67级防水，轻松应对洗手淋雨',
                image:require('@/assets/sh.png')
            }
        ],
        about:{
            describe:'安徽倾行网络科技有限公司是一家智能运动健身服务企业，以推动家庭轻运动健身事业为使命，以成为智能运动健身领域领先企业为目标，自主研发倾行线上APP，建立丰富的课程体系，推出系列运动健身智能硬件，打造软件+硬件+服务的全生态模式，满足用户全方位需求。',
            develop:[
                    2019,
                    '5.21 倾行品牌正式成立',
                    '8月   倾行APP推出市场' ,
                    2020,
                    '4月   倾行APP1.0版本全网上线',
                    '5月   倾行六大品牌体系形成',
                    2021,
                    '5.21 倾行品牌成立两周年'
            ],
            development:[
                require('@/assets/aboutDivision1.png'),
                require('@/assets/aboutDivision2.png'),
                require('@/assets/aboutDivision3.png'),
                require('@/assets/aboutDivision4.png'),
                require('@/assets/aboutDivision5.png'),
                require('@/assets/aboutDivision6.png'),
                require('@/assets/aboutDivision7.png'),
                require('@/assets/aboutDivision8.png'),
            ]
        }
    },
    mutations: {
    },
    actions: {
        toDownload(context,data){
            if(data=='ios'){
                window.open(context.state.allPage.downloadIosUrl)
            }else{
                window.open(context.state.allPage.downloadAndroidUrl)
            }
        }
    }
}
export default pageInfo