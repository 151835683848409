import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // 路由级代码拆分
    // 这将为此路由生成一个单独的块（about.[hash].js）
    // 访问路由时延迟加载.
    component: () => import(/* webpackChunkName: "index" */ '../views/page')
  },
  {
    path:'/AppPage',
    name:'AppPage',
    component:()=> import(/*webpackChunkName:"AppPage"*/ '../views/page/AppPage')
  },
  {
    path:'/equipment',
    name:'equipment',
    component:()=> import(/*webpackChunkName:"equipment"*/ '../views/page/equipment')
  },
  {
    path:'/about',
    name:'about',
    component:()=> import(/*webpackChunkName:"about"*/ '../views/page/about')
  },{
    path:'/dynamicList',
    name:'dynamicList',
    component:()=> import(/*webpackChunkName:"dynamicList"*/ '../views/page/dynamicList')
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode:'',
  base: process.env.BASE_URL,
  routes
})

export default router
